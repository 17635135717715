import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { StudentConsumer } from "../../../data/student";
import { flattenObject } from "../../../utils/flatten-object";
import { Overlay } from "./Overlay";

import { Loader } from "./Loader";
import {
    EDUCATION_LEVEL_ADULT,
    EDUCATION_LEVEL_ELEMENTARY,
    TYPE_ADULT
} from "./constants";

const SelectStudent = props => {
    const Component = lazy(() => import("./01-select-student"));
    return <Component {...props} />;
};
SelectStudent.STEP_KEY = "typeStudent";

const SelectCourse = props => {
    const Component = lazy(() => import("./02-select-course"));
    return <Component {...props} />;
};
SelectCourse.STEP_KEY = "course";

const Contact = props => {
    const Component = lazy(() => import("./03-contact"));
    return <Component {...props} />;
};
Contact.STEP_KEY = "contact";

const Completed = lazy(() => import("./04-completed"));
const ErrorScreen = lazy(() => import("./ErrorScreen"));

const STEPS = [SelectStudent, SelectCourse, Contact, Completed];

const ERROR_MAP = {
    student_type: SelectStudent,
    courses: SelectCourse,
    education_level: SelectCourse,
    signup_remarks: SelectCourse,
    year: SelectCourse,
    "user.postal_code": Contact,
    "user.email": Contact,
    "user.telephone": Contact,
    "user.firstName": Contact,
    "user.lastName": Contact,
    "user.address_street": Contact,
    "user.house_number": Contact,
    conditions_accepted: Contact
};

const ERROR_KEYS_PER_STEP = {};
for (let key in ERROR_MAP) {
    let step = ERROR_MAP[key];

    if (!ERROR_KEYS_PER_STEP[step]) {
        ERROR_KEYS_PER_STEP[step] = [];
    }
    ERROR_KEYS_PER_STEP[step].push(key);
}

const getCurrentStep = key => {
    return STEPS.find(component => component.STEP_KEY === key);
};

/**
 * Fat component to handle the interactive signup UX.
 *
 * This component keeps state and passes it down to the individual steps
 * as props, so that those components can stay 'dumb' or at least isolated.
 *
 * Any time a step is submitted, the callbacks come back to this component,
 * which is responsible for updating and saving the state, and progressing
 * through the steps.
 *
 * Note that there's a STEPS constant declared above - this is the single
 * source of truth for the existing steps and their order. Everything else
 * in this component relies on that. Each step needs their own set of props,
 * which is implemented in `getStepProps`. That should be the only method
 * you need to touch + possible new event handlers belonging to that step.
 */
export class InteractiveSignup extends React.PureComponent {
    /**
     * Initialize the state of the component from the props passed.
     * @param  {Object} props The React props, as passed in from the caller.
     */
    constructor(props) {
        super(props);

        this.studentConsumer = new StudentConsumer();

        this.state = {
            ...this._getInitialState(),
            signupError: false,
            sendingData: false,
            focus: false
        };
    }

    _getInitialState() {
        const { getState } = this.props;

        let state = getState();
        state.transitioningBetweenSteps = false;

        if (!state.currentStep) {
            state.currentStep = STEPS[0].STEP_KEY;
        }

        return state;
    }

    componentDidMount() {
        this.onHashChange();
        window.addEventListener("hashchange", this.onHashChange, false);
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.onHashChange, false);
    }

    onHashChange = () => {
        const hash = window.location.hash?.split("?");
        if (hash?.[0] === "#register") {
            this.setState({ focus: true });
        }
    };

    /**
     * Persist the new state to the save handler used (localStorage, currently)
     */
    _saveState() {
        const { saveState } = this.props;
        const state = Object.assign({}, this.state);
        saveState(state);
    }

    get CurrentStep() {
        const currentStepKey = this.state.currentStep;
        return getCurrentStep(currentStepKey);
    }

    /**
     * Advance to the next step
     */
    nextStep() {
        this.setTransitioning();
        const NextStep = STEPS[STEPS.indexOf(this.CurrentStep) + 1];
        this.setState({ currentStep: NextStep.STEP_KEY }, this._saveState);
    }

    /**
     * Return to the previous step
     */
    prevStep(data = {}) {
        this.setTransitioning();
        const PrevStep = STEPS[STEPS.indexOf(this.CurrentStep) - 1];
        this.setState(
            { currentStep: PrevStep.STEP_KEY, ...data },
            this._saveState
        );
    }

    /**
     * Set the state of the steps to transitioning, which will apply a visual
     * transition effect.
     */
    setTransitioning() {
        this.setState({ transitioningBetweenSteps: true }, () => {
            window.setTimeout(
                () => this.setState({ transitioningBetweenSteps: false }),
                200 // ms
            );
        });
    }

    /**
     * Calculate the progress of the current step in all the steps
     * @return {Number} A number between 0-100 to indicate progress as a
     * percentage
     */
    getProgress() {
        const index = STEPS.indexOf(this.CurrentStep);
        return 100 * (index / (STEPS.length - 1));
    }

    /**
     * Handler for the student-type selection step.
     *
     * After the state is updated, the state is persisted and the next step is
     * activated.
     *
     * @param  {String} typeStudent The type of student - student or adult
     */
    onStudentSelect = typeStudent => {
        if (typeStudent !== this.state[SelectStudent.STEP_KEY]) {
            this.setState({ [SelectCourse.STEP_KEY]: { courses: [] } });
        }
        this.setState({ [SelectStudent.STEP_KEY]: typeStudent }, () => {
            this._saveState();
            this.nextStep();
        });
    };

    /**
     * Handler for the course-selection step.
     *
     * TODO: this will change to some autocomplete value.
     *
     * @param  {Object} state The state of the step, as passed in from the
     * step component.
     */
    onCourseSelect(state) {
        this.setState(
            {
                [SelectCourse.STEP_KEY]: state
            },
            () => {
                this._saveState();
                this.nextStep();
            }
        );
    }

    onSetContact = contact => {
        this.setState({ [Contact.STEP_KEY]: contact }, () => {
            this._saveState();
            this.onComplete();
        });
    };

    /**
     * Send the state to the backend to create the student.
     */
    onComplete = () => {
        // clear any validation errors
        this.setState({ validationErrors: {}, sendingData: true });
        const { educationLevel } = this.state[SelectCourse.STEP_KEY];
        // map the data to what the backend expects
        const data = {
            student_type: this.state[SelectStudent.STEP_KEY],
            courses: this.state[SelectCourse.STEP_KEY].courses.map(
                course => course.id
            ),
            other_course_name: this.state[SelectCourse.STEP_KEY]
                .otherCourseName,
            education_level: educationLevel,
            signup_remarks: this.state[SelectCourse.STEP_KEY].text,
            user: {
                first_name: this.state[Contact.STEP_KEY].firstName,
                last_name: this.state[Contact.STEP_KEY].lastName,
                business_name: this.state[Contact.STEP_KEY].companyName,
                postal_code: this.state[Contact.STEP_KEY].postalCode,
                email: this.state[Contact.STEP_KEY].email,
                telephone: this.state[Contact.STEP_KEY].telephone,
                address_street: this.state[Contact.STEP_KEY].addressStreet,
                house_number: this.state[Contact.STEP_KEY].houseNumber
            },
            year:
                educationLevel === EDUCATION_LEVEL_ELEMENTARY
                    ? this.state[SelectCourse.STEP_KEY].year
                    : undefined,
            conditions_accepted: this.state[Contact.STEP_KEY].conditionsAccepted
        };
        return this.studentConsumer
            .interactiveSignup(data)
            .then(() => {
                this.props.clearState();
                // Redirect to onboarding
                window.location.href = this.props.completeUrl;
            })
            .catch(err => {
                if (err.statusCode !== 400) {
                    this.setState({ signupError: true });
                    throw err;
                }

                const { response } = err.statusText;
                const data = JSON.parse(response.data);
                this.handleValidationErrors(data);
                console.error(err);
                this.setState({ sendingData: false });
            });
    };

    handleValidationErrors(errors) {
        if (errors) {
            // flattening into a different format makes the mapping between fields <-> steps easier
            let flattened = flattenObject(errors);

            // figure out the earliest step to activate
            let stepsWithErrors = [];
            for (let key of Object.keys(flattened)) {
                let step = ERROR_MAP[key];
                if (step && !stepsWithErrors.includes(step)) {
                    stepsWithErrors.push(step);
                }
            }

            // sort to find the earliest step
            stepsWithErrors.sort((stepA, stepB) => {
                let indexA = STEPS.indexOf(stepA);
                let indexB = STEPS.indexOf(stepB);
                return indexA - indexB;
            });

            this.setState({
                validationErrors: flattened,
                // activate the first step with errors
                currentStep: stepsWithErrors[0].STEP_KEY
            });
        }
    }

    /**
     * Determine the props for the Step components.
     *
     * Every step component must always take the progress, nextStep and
     * prevStep in case they want to show the progress and/or navigation arrows.
     *
     * @param  {React.Component} step The Step component class to get the props for
     * @return {object}      The set of props to pass to the step component.
     */
    getStepProps(step) {
        const { validationErrors = {} } = this.state;
        let props = {};

        const stepProps = {
            nextStep: this.nextStep.bind(this),
            prevStep: this.prevStep.bind(this),
            progress: this.getProgress(),
            focus
        };

        // add in validation errors
        const errorKeys = ERROR_KEYS_PER_STEP[step] || [];
        const errors = {};
        for (let key of errorKeys) {
            let _errors = validationErrors[key];
            if (_errors) {
                let shortKey = key.split(".").reverse()[0];
                errors[shortKey] = _errors;
            }
        }
        stepProps.validationErrors = errors;

        switch (step.STEP_KEY) {
            case SelectStudent.STEP_KEY:
                props = {
                    onStudentSelect: this.onStudentSelect,
                    focus: this.state.focus,
                    phone: this.props.phone
                };
                break;

            case SelectCourse.STEP_KEY: {
                const stepState = this.state[SelectCourse.STEP_KEY] || {};
                const studentType = this.state[SelectStudent.STEP_KEY];
                props = {
                    ...stepState,
                    isNl: this.props.isNl === "True",
                    onSubmit: this.onCourseSelect.bind(this),
                    educationLevel:
                        stepState.educationLevel ||
                        (studentType === TYPE_ADULT
                            ? EDUCATION_LEVEL_ADULT
                            : ""),
                    studentType
                };
                break;
            }

            case Contact.STEP_KEY: {
                const studentType = this.state[SelectStudent.STEP_KEY];
                props = {
                    onSetContact: this.onSetContact,
                    ...this.state[Contact.STEP_KEY],
                    studentType,
                    companyName: this.props.companyName,
                    askForConditionsConsent: this.props.askForConditionsConsent
                };
                break;
            }

            case Completed.STEP_KEY:
                props = {
                    completeUrl: this.props.completeUrl
                };
                break;

            default:
                console.error(`Unknown step key: ${step.STEP_KEY}`);
        }

        props = Object.assign(props, stepProps);
        return props;
    }

    onClose() {
        const { clearState } = this.props;
        clearState();
        this.setState({
            currentStep: STEPS[0].STEP_KEY,
            validationErrors: {},
            focus: false
        });

        if (window.location.hash === "#register") {
            window.location.hash = "";
        }
    }

    render() {
        const {
            transitioningBetweenSteps,
            signupError,
            sendingData
        } = this.state;
        const CurrentStep = this.CurrentStep;
        const stepProps = this.getStepProps(CurrentStep);

        // show overlay if there's interaction with the interactive signup
        const focus = STEPS.indexOf(CurrentStep) > 0 || this.state.focus;
        const navbar = document.querySelector(".navigation");
        if (focus) {
            document.body.classList.add("fixed");
            navbar.classList.add("fixed");
        } else {
            document.body.classList.remove("fixed");
            navbar.classList.remove("fixed");
        }

        const className = classNames("interactive-signup", {
            "interactive-signup--focused": focus,
            "interactive-signup--centered": STEPS.indexOf(CurrentStep) > 0
        });

        const screenClassName = classNames(
            "interactive-signup__screen",
            { "interactive-signup__screen--focused": focus },
            {
                "interactive-signup__screen--transitioning": transitioningBetweenSteps
            }
        );

        return (
            <Overlay focus={focus} onClose={this.onClose.bind(this)}>
                <div className={className}>
                    <div className="interactive-signup__wrapper">
                        <div className={screenClassName}>
                            {signupError ? (
                                <ErrorScreen />
                            ) : sendingData ? (
                                <Loader />
                            ) : (
                                <Suspense fallback={null}>
                                    <CurrentStep
                                        key={CurrentStep.STEP_KEY}
                                        {...stepProps}
                                        focus={focus}
                                        onClose={this.onClose.bind(this)}
                                    />
                                </Suspense>
                            )}
                        </div>
                    </div>
                </div>
            </Overlay>
        );
    }
}

InteractiveSignup.propTypes = {
    getState: PropTypes.func.isRequired,
    saveState: PropTypes.func.isRequired,
    clearState: PropTypes.func.isRequired,
    completeUrl: PropTypes.string.isRequired,
    phone: PropTypes.string,
    companyName: PropTypes.string,
    isNl: PropTypes.bool
};
