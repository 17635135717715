import Swiper, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";

export const options = {
    spaceBetween: 30,
    centeredSlides: true,
    width: 255,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
    navigation: {
        nextEl: ".swiper-button-next"
    }
};

Swiper.use([Autoplay, Pagination, Navigation]);

export default Swiper;
