import React from "react";
import ReactDOM from "react-dom";
import BEM from "bem.js";
import Swiper, { options } from "../../components/swiper";
import { InteractiveSignup } from "../../components/react/interactive-signup/InteractiveSignup";
import { IntlProvider, addLocaleData } from "react-intl";
import { getLocale, getMessages, locales } from "../../messages";

const getState = () => {
    const state = window.localStorage.getItem("interactive-signup");
    if (state === null) {
        return {};
    }
    return JSON.parse(state);
};

const saveState = state => {
    window.localStorage.setItem("interactive-signup", JSON.stringify(state));
};

const clearState = () => {
    window.localStorage.removeItem("interactive-signup");
};

(function init() {
    const nodes = Array.from(BEM.getBEMNodes("react-interactive-signup"));
    const locale = getLocale() || "nl";
    const messages = getMessages(locale);
    addLocaleData(locales);

    nodes.map(node => {
        ReactDOM.render(
            <IntlProvider locale={locale} key={locale} messages={messages}>
                <InteractiveSignup
                    getState={getState}
                    saveState={saveState}
                    clearState={clearState}
                    askForConditionsConsent={!locale.includes("nl-nl")}
                    {...node.dataset}
                />
            </IntlProvider>,
            node
        );
    });

    new Swiper(".swiper-testimonials", options);
})();
