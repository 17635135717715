/**
 * Data layer for Student retrieval during interactive signup.
 */
import { CrudConsumer, CrudConsumerObject } from "consumerjs";

/**
 * A Student object.
 *
 * It has the members author, text and avatar, which are all text. The avatar
 * text is the URL to the image file.
 */
class Student extends CrudConsumerObject {}

export class StudentConsumer extends CrudConsumer {
    constructor(endpoint = "/api/students/", objectClass = Student) {
        super(endpoint, objectClass);
    }

    interactiveSignup(data) {
        return this.post("signup/", data);
    }
}
